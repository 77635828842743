/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. 
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import 'media-mixins';
@import './theme/margin-padding';
@import './theme/input-angle';
@import './theme/tooltip.scss';

.inner-content {
  padding-left: var(--space-3xl);
  padding-right: var(--space-3xl);
  max-width: 600px;
}

ion-modal.helpModal {
  --width: 350px;
}
@include phone {
  html {
    font-size: 1rem;
  }
}

.full-width {
  width: 100%;
}
.input-wrapper {
  // box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  font-size: var(--font-size-l) !important;
  --padding-start: 6px;
  --inner-padding-end: 3px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.range-bg {
  border-bottom: 1px solid var(--color-secondary);
  overflow: hidden;
  height: 18px;
  div {
    position: relative;
    bottom: -8px;
    background-color: var(--color-primary);
    height: inherit;
    transform: skew(-0.01turn, -1.1deg);
    @include phone {
      transform: skew(-0.01turn, -1.5deg);
    }
  }
}

.border-full {
  border: 1px solid var(--color-light-2);
  @include phone {
    width: 100vw;
    transform: scaleX(2);
  }
}

.flex-end {
  justify-content: flex-end;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.align-center {
  align-items: center;
}
.border-left-light-2 {
  border-left: 1px solid var(--color-light-2);
}
.border-right-shadow {
  box-shadow: 2px 1px 2px 0 rgb(0 0 0 / 20%);
}

.text-center {
  text-align: center;
}
.border-top-error {
  border-top: 2px solid var(--color-error) !important;
}

.border-right-error {
  border-right: 2px solid var(--color-error) !important;
}

.border-bottom-error {
  border-bottom: 2px solid var(--color-error) !important;
}
.border-left-error {
  border-left: 2px solid var(--color-error) !important;
}
.border-error {
  @extend .border-top-error;
  @extend .border-right-error;
  @extend .border-bottom-error;
  @extend .border-left-error;
}

ion-modal.addGradeModal {
  --height: auto !important;
  --width: 400px;
  --min-height: 200px;

  .ion-page {
    position: relative;
    contain: content;
    max-height: 90vh;
    overflow-y: auto;
  }
  @include phone {
    --height: 100% !important;
    --width: 100%;
    .ion-page {
      max-height: 100vh;
    }
  }
}

.margin-left-40percent {
  margin-left: 40%;
}

.padding-left-4xl {
  padding-left: var(--space-4xl);
}

.padding-left-2xl {
  padding-left: var(--space-2xl);
}
.padding-right-4xl {
  padding-right: var(--space-4xl);
}
.padding-right-2xl {
  padding-right: var(--space-2xl);
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(var(--color-dark-rgb), 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: var(--color-medium);
}
.sc-ion-alert-md-h,
.sc-ion-alert-ios-h {
  --max-width: 290px;
}

help-modal {
  ion-title {
    padding-inline: var(--space-l, 21px);
    text-align: left;
  }
}
